export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"LSI Scheduler"}],"link":[{"rel":"stylesheet","href":"https://callcenter.smg.sg/lib/fcalendar/css/core.css"},{"rel":"stylesheet","href":"https://callcenter.smg.sg/lib/fcalendar/css/daygrid.css"},{"rel":"stylesheet","href":"https://callcenter.smg.sg/lib/fcalendar/css/timegrid.css"},{"rel":"icon","type":"image/x-icon","href":"/images/logo.png?version=1","sizes":"192x192"},{"rel":"preconnect","href":"https://fonts.gstatic.com"}],"style":[],"script":[{"src":"https://callcenter.smg.sg/lib/fcalendar/js/core.js","type":"text/javascript"},{"src":"https://callcenter.smg.sg/lib/fcalendar/js/interaction.js","type":"text/javascript"},{"src":"https://callcenter.smg.sg/lib/fcalendar/js/daygrid.js","type":"text/javascript"},{"src":"https://callcenter.smg.sg/lib/fcalendar/js/timegrid.js","type":"text/javascript"},{"src":"https://callcenter.smg.sg/lib/fcalendar/js/resource-common.js","type":"text/javascript"},{"src":"https://callcenter.smg.sg/lib/fcalendar/js/resource-daygrid.js","type":"text/javascript"},{"src":"https://callcenter.smg.sg/lib/fcalendar/js/resource-timegrid.js","type":"text/javascript"}],"noscript":[],"title":"LSI Scheduler"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'