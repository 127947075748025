import { defu } from "defu";
import { useAuth0 } from "~/services/auth0.service";

const formatUrl = (url) => {
  //remmove last char / if exist
  if (url[url.length - 1] === "/") {
    url = url.slice(0, -1);
  }
  return url;
};

export async function useCustomFetch(url, options) {
  const auth0Client = await useAuth0();
  try {
    const token = await auth0Client.getTokenSilently();
    const config = useRuntimeConfig();
    const baseUrl = formatUrl(config.public.NUXT_PUBLIC_API_ENDPOINT || "http://localhost:3000") + "/api";
    const isOtherDomain = url.startsWith("http");

    const defaults = {
      baseURL: isOtherDomain ? url : baseUrl,
      // cache request
      key: isOtherDomain ? "" : url,

      // set user token if connected
      headers: token && url.includes("/auth") ? { Authorization: token } : {},

      onResponse(_ctx) { },

      onResponseError(_ctx) {
        console.log("onResponseError", _ctx?.response);
        if (_ctx?.response?.status === 401) {
          auth0Client.logout();
          window.location.href = "/";
        }
        return _ctx?.response?._data;
      },
    };

    // for nice deep defaults, please use unjs/defu
    const params = defu(options, defaults);
    console.log(`Making request to ${baseUrl + url}`);
    return $fetch(url, params).then((res) => ({
      data: {
        value: res,
      },
      error: {
        value: null
      }
    }), (error) => {
      return {
        data: null,
        error: {
          value: error
        }
      }
    })
  } catch (error) {
    auth0Client.logout();
    window.location.href = "/";
  }

}
