import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as indexE9M0F0TuqEMeta } from "/vercel/path0/pages/auth0/callback/index.vue?macro=true";
import { default as index98lWnPghHgMeta } from "/vercel/path0/pages/auth0/logout/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
export default [
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexE9M0F0TuqEMeta?.name ?? "auth0-callback",
    path: indexE9M0F0TuqEMeta?.path ?? "/auth0/callback",
    meta: indexE9M0F0TuqEMeta || {},
    alias: indexE9M0F0TuqEMeta?.alias || [],
    redirect: indexE9M0F0TuqEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth0/callback/index.vue").then(m => m.default || m)
  },
  {
    name: index98lWnPghHgMeta?.name ?? "auth0-logout",
    path: index98lWnPghHgMeta?.path ?? "/auth0/logout",
    meta: index98lWnPghHgMeta || {},
    alias: index98lWnPghHgMeta?.alias || [],
    redirect: index98lWnPghHgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth0/logout/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOBDY93e9EMeta?.name ?? "login",
    path: indexoOBDY93e9EMeta?.path ?? "/login",
    meta: indexoOBDY93e9EMeta || {},
    alias: indexoOBDY93e9EMeta?.alias || [],
    redirect: indexoOBDY93e9EMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  }
]