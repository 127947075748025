import { createAuth0Client } from '@auth0/auth0-spa-js';

export const useAuth0 = async () => {
    const config = useRuntimeConfig()
    const auth0Client = await createAuth0Client({
        domain: config.public.NUXT_OAUTH_AUTH0_DOMAIN,
        clientId: config.public.NUXT_OAUTH_AUTH0_CLIENT_ID,
        authorizationParams: {
            audience: config.public.NUXT_OAUTH_AUTH0_AUDIENCE   // NEW - add the audience value
        },
        cacheLocation: 'localstorage',
        useRefreshTokens: true
    });
    return auth0Client;
}