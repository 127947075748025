import moment from "moment";
import countryCodes from '~/mock/countrycodes.json'
import avatarColors from '~/mock/avatarcolors.json'

export function firstCharUpperCase(value) {
  if (!value) return;
  return `${value.charAt(0).toUpperCase()}${value.substr(1, value.length)}`;
}

export function valueToDisplay(value, key = '_') {
  if (!value) return;
  let separateWord = value.toLowerCase().split(key);
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1);
  }
  return separateWord.join(' ');
}

export function toUSD(value) {
  if (!value) return "-";
  return `$${value}`;
}

export function date(value, type = "ll") {
  if (!value) return "-";
  return moment(value).format(type);
}

export function formatCurrency(value) {
  if (value == null || value == undefined || isNaN(value) || typeof value !== "number") {
    return "$0";
  }
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // "SGD",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
}

export function prettyBytes(num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    // throw new TypeError('Expected a number');
    return '-';
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
}

export const getCountryFromCode = (value) => {
  const res = countryCodes.find(o => o.code === value);
  if (res) {
    return res.name
  }
  else {
    return value
  }
}

export const formatNumber = (value, prefix = '', fixed = 0) => {
  if (typeof value == 'undefined' || value === null) return ''
  let val = fixed > 0 ? (value / 1).toFixed(0) : value
  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (fixed > 0) {
    val = parseFloat(val).toFixed(fixed)
  }
  return `${prefix}${val}`
}

export function getOrdinalNumber(number) {
  var suffix = 'th';

  if (number % 100 !== 11 && number % 10 === 1) {
    suffix = 'st';
  } else if (number % 100 !== 12 && number % 10 === 2) {
    suffix = 'nd';
  } else if (number % 100 !== 13 && number % 10 === 3) {
    suffix = 'rd';
  }

  return number + suffix;
}

export function avatarText(value) {
  if (!value) return "-";
  let separateWord = value.split(" ");

  let text = "";
  if (separateWord.length > 1) {
    text = separateWord[0].charAt(0).toUpperCase() + separateWord[1].charAt(0).toUpperCase();
  }
  else {
    text = separateWord[0].charAt(0).toUpperCase();
  }
  return text;
}

export function avatarColor(value) {
  const firstChar = value ? value.charAt(0).toLowerCase() : 'a';
  return avatarColors[firstChar];
}

const filters = {
  firstCharUpperCase,
  valueToDisplay,
  toUSD,
  date,
  formatCurrency,
  prettyBytes,
  getCountryFromCode,
  formatNumber,
  getOrdinalNumber,
  avatarText,
  avatarColor
};

export default defineNuxtPlugin((nuxtApp) => {

  nuxtApp.vueApp.config.globalProperties.$filters = {
    ...filters,
  }

})

