import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import ImageUploader from "quill-image-uploader";
import ImageResize from '@taoqf/quill-image-resize-module';
import Quill from "quill";

export default defineNuxtPlugin(nuxtApp => {
  Quill.register("modules/imageUploader", ImageUploader);
  Quill.register("modules/imageResize", ImageResize);
  nuxtApp.vueApp.component('QuillEditor', QuillEditor);
})